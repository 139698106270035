<template>
  <section class="containerResultsWork">
    <resultsPage :config="configuration"
      @openForm="goToEditionPage" />
  </section>
</template>

<script>
import { ref } from 'vue'
import store from '../../store/index'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import resultsPage from '@/components/resultsPage/resultsPage'
import configurationFile from './resultsWork.json'

export default {
  name: 'work',
  components: {
    resultsPage
  },
  beforeRouteEnter () {
    store.commit({
      type: 'CHANGE_DEFAULT_FLOW',
      defaultSource: 'work'
    })
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const configuration = ref(Object.assign(configurationFile.config, { isUploadButtonVisible: store.getters.actionCanBePerformed('create', 'WorkItem') && !store.state.isMobile }))

    /**
     * @description Redirects the user to page to edit or upload a new file.
     */
    function goToEditionPage () {
      router.push('workEdition')
    }

    return {
      store,
      router,
      configuration,
      goToEditionPage
    }
  }
}
</script>
